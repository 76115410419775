class MatrizService {
  constructor() {}
  resources = () => ({
    listarTiposGasto: {
      uri: `/v1/presupuesto/listar-tipo-gasto`,
      method: ['get']
    },
    exportarTiposGasto: {
      uri: `/v1/exportartipogasto`,
      method: ['get']
    },
    exportarModeloTiposGasto: {
      uri: `/v1/exportarformatotipogasto`,
      method: ['get']
    },
    importarTiposGasto: {
      uri: `/v1/presupuesto/importar-tipo-gasto`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    permisos: {
      uri: `/v1/usuario/permisos`,
      method: ['get']
    }
  });
}

export default MatrizService;
